enum ApiTags {
  agenti = 'AGENTI',
  clienti = 'CLIENTI',
  login = 'LOGIN',
  manualOfflineMode = 'MANUAL_OFFLINE_MODE',
  me = 'ME',
}

const isOffline = () => !!localStorage.getItem('manual-offline');

enum LSKeys {
  manualOffline = 'manual-offline',
  me = 'me',
}

enum Paths {
  agenti = '/agenti',
  clienti = '/clienti',
  home = '/',
  login = '/login'
}

enum FeatureFlags {}

export {
  ApiTags,
  Paths,
  FeatureFlags,
  isOffline,
  LSKeys,
};
